/* Custom AboutScreen.css */

/* Center the about content vertically */
.bg-image {
  /* The image used */
  background-image: url(../assets/bg.png);
  margin-top: 20px;

  /* Add the blur effect */
  /* filter: blur(8px); */
  /* -webkit-filter: blur(8px); */

  /* Full height */
  height: 90vh;
  width: 100%;
  z-index: -1;
  opacity: 0.5;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-content {
    background-color: rgb(247, 247, 247);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border: solid;
    border-color: #0f55a0;
    position: relative;
    width: 700px;
    margin: 0 auto;
  }

  .aboutus-section {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Styling for the header */
  .about-content h2 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 30px;
    color: #007bff;
  }
  
  /* Add some spacing between paragraphs */
  .about-content p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.6;
    color: #000;
  }
  
  .box{
    border-color: #007bff;
  }
  
  /* body{
    background-image: url(../Users/admin/Desktop/2672335.jpg);
  } */
  .info-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    width: 500px;
    margin: 0 auto;
  }

  .profile-image {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #ccc;
  }
  
  .social-links {
    text-align: center;
    margin-top: 10px;
  }
  
  .social-links a {
    color: #333;
    margin-right: 10px;
    font-size: 24px;
  }
  
  .social-links a:last-child {
    margin-right: 0;
  }