/* TemplatesScreen.css */

.template-col {
    margin-bottom: 20px;
    margin-top: 70px;
  }
  
  .template-card {
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .template-card:hover {
    transform: scale(1.05);
  }
  
  .modal-image {
    width: 100%;
    height: auto;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  