/* LoginScreen.css */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login-screen {
  padding: 40px 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(../assets/bg.png); */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  /* opacity: 1; */
  /* background-position: center; */
}

.login-card {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-card .card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  /* margin-left: 50%;
  margin-right: 50%; */
}

.login-card .form-group {
  margin-bottom: 20px;
}

.login-card .form-label {
  font-weight: bold;
}

.login-card .login-btn {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.login-card .google-btn {
  width: 100%;
}

.login-card .login-link {
  text-align: center;
  margin-top: 15px;
}

.login-card .login-link a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.login-card .login-link a:hover {
  text-decoration: underline;
}

.bg-image {
  /* The image used */
  background-image: url(../assets/bg.png);
  margin-top: 20px;

  /* Add the blur effect */
  /* filter: blur(8px); */
  /* -webkit-filter: blur(8px); */

  /* Full height */
  height: 90vh;
  width: 100%;
  z-index: -1;
  opacity: 0.5;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}