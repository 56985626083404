.transparent-card {
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin: 0px;
}

.left-card {
  background-color: #2c323d;
  border-radius: 10px 0 0 10px;
  /* padding: 20px; */
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px 0 0 10px;
  padding: 0px;
  margin: 0px;
  width: 700px;
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
}

.card {
  margin: 0;
  padding: 10px;
}

.font .semibold {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.font p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.name-font {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 40px;
}

.name-card p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.experience-card {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.experience .regular {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.experience .medium {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.experience .light {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 10px;
}

.right-card {
  border-radius: 0 10px 10px 0;
  padding: 0px;
  margin: 0px;
  /* padding: 20px; */
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.profile-info p {
  width: 95%;
}

.card-text-color {
  color: #fff;
}

.user-img-color {
  border: 1px solid #fff;
  background-color: #ebebeb;
  margin-top: 20px;
}

/* Add styles for the circles */
.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 4px; /* Add some spacing between circles */
  border-radius: 50%;
}

.filled-light {
  background-color: #fff; /* Add the color for filled circles */
}

.outline-light {
  background-color: #595f6b; /* Add the border for outline circles */
}

.filled-dark {
  background-color: #333; /* Add the color for filled circles */
}

.outline-dark {
  background-color: #d7d7d7; /* Add the border for outline circles */
}

/* Adjust the styles for the language item */
.language-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Style the circles container */
.circles-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.skill-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 50%;
}

/* Style the circles container for skills */
.skill-item .circles-container {
  display: flex;
  align-items: center;
}

.skills-row {
  display: flex;
  flex-wrap: wrap;
}

.contact-title {
  position: relative;
  margin-bottom: 20px;
}

.contact-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px; /* Adjust this value to control the distance of the line from the title */
  width: 30px;
  height: 2px; /* Adjust this value to control the thickness of the line */
  background-color: #fff; /* Set the color of the line here */
}

.profile-title {
  position: relative;
  margin-bottom: 20px;
}

.profile-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px; /* Adjust this value to control the distance of the line from the title */
  width: 30px;
  height: 2px; /* Adjust this value to control the thickness of the line */
  background-color: #000; /* Set the color of the line here */
}