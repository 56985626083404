/* HomeScreen.css */

/* Global Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333333;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: bold;
  }
  
  p {
    margin: 0;
  }

  .home-screen {
    padding-top: 50px;
  }
  
  /* Hero Section */
  .hero-section {
    background-color: #007bff;
    color: #fff;
    padding: 50px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .hero-section h1 {
    font-size: 48px;
    margin-bottom: 10px;
  }
  
  .hero-section p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .hero-section button {
    font-size: 20px;
    padding: 12px 30px;
    border-radius: 50px;
    background-color: #fff;
    color: #007bff;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .hero-section button .link {
    color: #007bff;
    text-decoration: none;
  }
  
  .hero-section button:hover {
    outline: none;
    color: #fff;
  }

  .hero-section button .link:hover {
    color: #fff;
  }
  
  /* Features Section */
  .features-section {
    /* background-color: #f8f9fa; */
    padding: 40px 0;
    text-align: center;
  }
  
  .features-section h3 {
    font-size: 32px;
    margin-bottom: 15px;
    color: #007bff;
  }
  
  .features-section p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* How It Works Section */
  .how-it-works-section {
    background-color: #fff;
    padding: 40px 0;
    text-align: center;
  }
  
  .how-it-works-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
    color: #007bff;
  }
  
  .how-it-works-section .step-box {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .how-it-works-section .step-box:hover {
    transform: translateY(-5px);
  }
  
  .how-it-works-section h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .how-it-works-section p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  /* Testimonials Section */
  .testimonials-section {
    /* background-color: #f8f9fa; */
    padding: 40px 0;
    text-align: center;
  }
  
  .testimonials-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
    color: #007bff;
  }
  
  .testimonials-section .testimonial {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .testimonials-section .testimonial:hover {
    transform: translateY(-5px);
  }
  
  .testimonials-section p {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .testimonials-section .testimonial p:last-child {
    margin-bottom: 0;
  }
  
  /* Why Choose Us Section */
  .why-choose-us-section {
    background-color: #007bff;
    padding: 40px 10px;
    text-align: center;
    color: #fff;
  }
  
  .why-choose-us-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .why-choose-us-section h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .why-choose-us-section p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* FAQ Section */
  .faq-section {
    /* background-color: #f8f9fa; */
    padding: 40px 0;
    text-align: center;
  }
  
  .faq-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
    color: #007bff;
  }
  
  .faq-section .faq {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    height: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .faq-section .faq:hover {
    transform: translateY(-5px);
  }
  
  .faq-section h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .faq-section p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* Contact Information */
  .contact-section {
    background-color: #007bff;
    padding: 40px 0;
    text-align: center;
    color: #fff;
  }
  
  .contact-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .contact-section p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  